<template>
    <section>
        <SideBar v-if="!OpenMode" />
        <TopBar v-if="!OpenMode" />
        <div :class="[!OpenMode ? 'content' : '' ]">   
            <div class="main-content-header">
                <h1>Maestro de Operadores</h1>
            </div>
            <LoadingContent v-if="isLoading" />
            <div class="row">
                <div class="col-lg-12 col-md-6">
                    <div class="card" :style="{height:[contentHeight+'px']}">
                        <div class="row " :class="[!OpenMode ? 'p-4' : '']">
                            <div class="content-header col-12"> 
                                <div class="row">
                                    <div class="col-md-4 col-12 text-start ">
                                        <div class="row">
                                            <label for="suc" class="col-2 text-nowrap">Sucursal</label>
                                            <div class="col-10">
                                                <select id="suc" v-model="filtroSucursal" @change="consultar()" class="form-control form-control-sm"> 
                                                    <option value="0">Todas las Sucursales</option>
                                                    <option v-for="suc in sucursales" :key="suc.sucursal" :value="suc.sucursal">{{suc.nombre}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3 d-none d-md-flex text-start"></div>
                                    <div class="col-md-3 col-12 text-start">
                                        <div class="row">
                                            <label for="est" class="col-5 text-nowrap text-end">Mostrar</label>
                                            <div class="col-7">
                                                <select id="est" v-model="filtroEstado"  @change="consultar()"  class="form-control form-control-sm">
                                                    <option value="A">Activos</option>
                                                    <option value="I">Inactivos</option>
                                                    <option value="T">Todos</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-12"><p class="p-1 pe-4 mb-0 text-end">Página {{ paginaActual }} de {{ totalPaginas }} </p></div>
                                    <div class=" col-12 col-md-2 offset-md-10 ps-2 pe-5 text-end pb-0 pt-0">
                                        <ul class="list-unstyled mb-0 d-flex justify-content-between pager-btns float-right">
                                            <li><button @click="setPaginacion('first')" type="button" class="pager pager-first btn btn-sm btn-outline-secondary"><ChevronDoubleLeftIcon /></button></li>
                                            <li><button @click="setPaginacion('prev')"  type="button" class="pager pager-prev  btn btn-sm btn-outline-secondary"><ChevronLeftIcon /></button></li>
                                            <li><button @click="setPaginacion('next')"  type="button" class="pager pager-next  btn btn-sm btn-outline-secondary"><ChevronRightIcon /></button></li>
                                            <li><button @click="setPaginacion('last')"  type="button" class="pager pager-last  btn btn-sm btn-outline-secondary"><ChevronDoubleRightIcon /></button></li> 
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-10 col-12 pt-2">
                                <div class="table-responsive ps-2">
                                    <b-table-simple class="browse" hover small caption-top responsive >
                                        <b-thead head-variant="light" class="table-header">
                                            <b-tr>
                                                <b-th class="text-nowrap">D</b-th>
                                                <b-th class="text-nowrap">Suc</b-th>
                                                <b-th class="text-nowrap">Número</b-th>
                                                <b-th class="text-nowrap">Nombre del Operador</b-th>
                                                <b-th class="text-nowrap">R.F.C.</b-th>
                                                <b-th class="text-nowrap">Calle y Número</b-th>
                                                <b-th class="text-nowrap">Colonia</b-th>
                                                <b-th class="text-nowrap">Ciudad</b-th>
                                                <b-th class="text-nowrap">Estado</b-th>
                                                <b-th class="text-nowrap">C.Postal</b-th>
                                                <b-th class="text-nowrap">Teléfono 1</b-th>
                                                <b-th class="text-nowrap">Teléfono 2</b-th>
                                                <b-th class="text-nowrap">Teléfono 3</b-th>
                                                <b-th class="text-nowrap">F.Ingreso</b-th>
                                                <b-th class="text-nowrap">F.Baja</b-th>
                                                <b-th class="text-nowrap">Licencia</b-th>
                                                <b-th class="text-nowrap">Resello</b-th>
                                                <b-th class="text-nowrap">L.Expiración</b-th>
                                                <b-th class="text-nowrap">Estatus</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr   v-for="(operador,indx) in operadores" 
                                                    :key="operador.numero" 
                                                    @click="selectedRegistro=operador;selectedRegistro.indx = indx" 
                                                    @dblclick=" (!OpenMode ? modificar() : exportRegistry()) " 

                                                    :class="[getStatus(operador),{   'son-text-danger': (operador.estatus  == 'I'),
                                                                'selected-row': (selectedRegistro.numero == operador.numero) }]"> 
                                                <b-td class="text-nowrap"><FileDocumentOutlineIcon v-if="operador.doctos > 0" /></b-td>
                                                <b-td class="text-nowrap">{{ operador.sucursal| leadingZeros(3)}}</b-td>
                                                <b-td class="text-center text-nowrap" >{{ operador.numero | leadingZeros(5)}}</b-td>
                                                <b-td class="text-nowrap">{{ operador.diasextraexpi }} - {{ operador.nombre }}</b-td>
                                                <b-td class="text-nowrap">{{ operador.rfc }}</b-td>
                                                <b-td class="text-nowrap">{{ operador.dir1 }}</b-td>
                                                <b-td class="text-nowrap">{{ operador.dir2}}</b-td>
                                                <b-td class="text-nowrap">{{ operador.ciudad}}</b-td>
                                                <b-td class="text-nowrap">{{ operador.estado}}</b-td>
                                                <b-td class="text-nowrap">{{ operador.codigopost}}</b-td>
                                                <b-td class="text-nowrap">{{ operador.tel1 }}</b-td>
                                                <b-td class="text-nowrap">{{ operador.tel2}}</b-td>
                                                <b-td class="text-nowrap">{{ operador.tel3 }}</b-td>
                                                <b-td class="text-nowrap">{{ formato(operador.fechaingreso,'MMM-DD-YYYY')}}</b-td>
                                                <b-td class="text-nowrap">{{ formato(operador.fechabaja,'MMM-DD-YYYY')}}</b-td>
                                                <b-td class="text-nowrap">{{ operador.licencia}}</b-td>
                                                <b-td class="text-nowrap">{{ formato(operador.sigresello,'MMM-DD-YYYY')}}</b-td>
                                                <b-td class="text-nowrap">{{ formato(operador.fechaexp,'MMM-DD-YYYY')}}</b-td>
                                                <b-td class="text-nowrap text-center">{{ operador.estatus}}</b-td>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </div>
                            </div> 
                            <div class="col-2 ps-2 pe-5 btn-manager" >
                                <ul class="d-block list-unstyled action-block">
                                    <li v-if="!OpenMode"><router-link to="/operadores/agregar" class="btn btn-xs btn-success btn-block">Agregar</router-link></li>
                                    <li v-if="!OpenMode"><button @click="modificar()" :disabled="disableBtn" type="button" class="btn btn-xs btn-primary btn-block">Cambiar</button></li>
                                    <li v-if="!OpenMode"><button @click="ask()" :disabled="disableBtn" class="btn btn-xs btn-danger btn-block">Borrar</button></li>
                                    <li v-if="!OpenMode"><button @click="imprimir()" class="btn btn-xs btn-secondary btn-block">Imprimir</button></li>
                                    <li v-if="OpenMode"><button @click="exportRegistry()" class="btn btn-xs btn-success btn-block">Elegir</button></li>
                                </ul>
                            </div>
                            
                            <div class="content-footer col-12 text-start"></div>
                        </div>
                    </div>
                </div>
            </div>
            <BusquedaVue v-show="busquedaVisible" v-hotkey="keymap" :campos="camposFiltro" @applyFilter="aplicaFiltro()"></BusquedaVue>
            <PrintComponentVue v-if="print" @close="closePopup">
                <template  v-slot:title >Reporte de Operadores</template>
            </PrintComponentVue>

            <DeleteReg  v-show="showDelete" @close="closeDelete" @aceptar="operadores = borrar('Operadores',operadores)">
                <template v-slot:header>
                    <h6 class="text-uppercase">{{notificationTitle}}</h6>
                </template>
                <template v-slot:body>
                    {{notificationText}}
                </template>
            </DeleteReg>

            <InfoPopUp  v-show="showNotification" @close="closeInfo" >
                <template v-slot:header>
                    <h6 class="text-uppercase">{{notificationTitle}}</h6>
                </template>
                <template v-slot:body>
                    {{notificationText}}
                </template>
            </InfoPopUp>
            
        </div>
    </section>
</template>

<script>
import InfoPopUp from '@/components/InfoPopUp.vue';
import DeleteReg from '@/components/YesNoPopup.vue';
import FileDocumentOutlineIcon from 'vue-material-design-icons/FileDocumentOutline.vue';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import ChevronDoubleLeftIcon from 'vue-material-design-icons/ChevronDoubleLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'
import handleBrowse from '@/mixins/handleBrowse'
import handlePopUp from '@/mixins/handlePopUp'
import PrintComponentVue from '@/components/PrintComponent.vue'; 
import BusquedaVue from '@/components/Busqueda.vue'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
import axios from 'axios'

export default {
    name:'WIN_Operadores',
    props:['mode','perpage'],
    mixins:[handleGlobals,handleBrowse,handlePopUp],
    components:{SideBar,TopBar,ChevronLeftIcon,InfoPopUp,DeleteReg,BusquedaVue,ChevronDoubleRightIcon,ChevronDoubleLeftIcon,ChevronRightIcon, LoadingContent,FileDocumentOutlineIcon,PrintComponentVue},
    data(){
        return{
            operadores: [],
            filtroEstado: "A",
            sucursales:[],
            filtroSucursal: 0,
            semana: ''
        }
    },
    created(){
       // this.inicializarFiltros()
        this.semana = new Date()
        this.semana.setDate(this.semana.getDate() + 7)
    //    this.paginacion = 'first'
    if(this.OpenMode == false){
            this.getFiltros()
        } 
    },
    mounted(){
        this.fetchSucursales()
        this.consultar()
    },
    methods:{
        async getFiltros(){
                await fetch(this.$api+'/rdx', {    
                    method: "POST",
                    headers: {
                        'Content-Type' : 'application/json',
                        'Authorization' : this.$store.state.iasctratkn
                    },
                    body: JSON.stringify({accion: "filters"})
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }
                }).then((data)=>{
                    let reg = data['OPE']
                    if(reg){
                        this.filtroEstado       = reg['estatus'].dato
                        this.filtroSucursales   = reg['sucursal'].dato
                        this.paginaActual       = reg['paginaActual'].dato
                        if(this.paginaActual == '') this.paginaActual = 0
                    }else{
                        this.filtroEstado       = "T"
                        this.filtroSucursales   = 0
                        this.paginaActual       = 1
                    }
                }).catch((err)=>{
                this.falseToken(err)
            });
            },
        consultar(){
            this.isLoading = true
            axios.post(this.$api+'/rdx',{  accion: "browse", 
                                    archivo: "Operadores", 
                                    activarPaginas: true,
                                    orden: "numero",
                                    filtro: {
                                        sucursal:{  dato:this.filtroSucursal, inactivo: (this.filtroSucursal == 0 ? true : false) }, 
                                        estatus: {  dato:this.filtroEstado, inactivo: (this.filtroEstado == 'T' ? true : false) },
                                        paginaActual: {  dato: 'NA'}
                                    }, 
                                    pagina: this.paginaActual, 
                                    paginacion: this.paginacion,
                                    registrosxpagina: this.rows },{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.iasctratkn
                }
            }).then((res)=>{
                if(res.msg){
                    this.msg_err = res.msg
                }else{
                    this.operadores     = res.data['registros'];
                    this.totalRegistros = res.data['totalRegistros'];
                    this.totalPaginas   = res.data['totalPaginas'];
                    this.paginaActual   = res.data['paginaActual']
                }
            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
            
        },
        fetchSucursales(){
            axios.post(this.$api+'/rdx',{ accion: "browse", 
                                        archivo: "InSucursales", 
                                        activarPaginas: false,
                                        orden: "sucursal",
                                        filtro: {paginaActual:{dato:'NA'}},
                                        pagina: 'todo', 
                                        paginacion: '',
                                        registrosxpagina: 0 },{
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.iasctratkn
                }
            }).then((res)=>{
                if(res.msg){
                    this.msg_err = res.msg
                }else{
                    this.sucursales = res.data['registros'];
                }
            }).finally(()=>{
                this.isLoading = false;
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        getStatus(operador){
            let tmpDate = this.formato(this.semana,'YYYY-MM-DD')
            let className = ''
           
            if(operador.diasextraexpi > 0){
                let tmpd = new Date()
                tmpDate = tmpd.setDate(this.semana.getDate() + operador.diasextraexpi)
                tmpDate = this.formato(tmpDate,'YYYY-MM-DD')
            }
           
            if(operador.fechaexpi < tmpDate){
                className = 'iLightMagenta' 
            }else{
                if((operador.sigresello instanceof Date && !isNaN(operador.sigresello)) && operador.sigresello < tmpDate){
                    className = 'naranja'
                }else{ 
                    className = 'default'
                }
            }
            return className
        },
        imprimir(){
            this.print = true
        },
        modificar(){
            this.$router.push({ name: 'COperadores', params: {operador: this.selectedRegistro.numero}});
        },
    }
}
</script>


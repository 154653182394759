import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
        iasctratkn: JSON.parse(localStorage.getItem('iasctratkn')),
        lookupFile: [],
        PARAMS: JSON.parse(localStorage.getItem('PARAMS')),
        session: JSON.parse(localStorage.getItem('session')),
        gpbSupervisor: false,
        gnAno: new Date().getFullYear(),
        gpsUsuario: 'IASC Pruebas',
        gsEmpresa: 'IASC',
        version: '1.0',
        database: 'TJG',
        source: 'Mi Lap',
        selectedRegistro: {},
        gnFolioMax:0,
        gsSistema: 'FacturacionWD',
        gbDigital: false,
        gbDesdeTrafico:false
    },
    getters: {

    },
    mutations: {
      setToken(state,token){
        state.iasctratkn = token
      },
     
      setParams(state,params){
        state.PARAMS = JSON.stringify(params)
      },
     
    },
    actions: {
      saveToken({commit},token){
        commit('setToken',token)
        localStorage.setItem('iasctratkn',JSON.stringify(token))
      },
    
      saveParams({commit},params){
        commit('setParams',params)
        localStorage.setItem('PARAMS',JSON.stringify(params))
      },
     
      logout({commit}){
        commit('setToken',null)
        localStorage.clear()
      }
    },
    modules: {
    }
});
import moment from 'moment'
export default {
    data(){
        return{
            currencyOptions: {
                "currency": "MXN",
                "currencyDisplay": "hidden",
                "hideCurrencySymbolOnFocus": true,
                "hideGroupingSeparatorOnFocus": true,
                "hideNegligibleDecimalDigitsOnFocus": false,
                "autoDecimalDigits": true,
                "useGrouping": true,
                "accountingSign": false
            },
            parametros:{},
            Gfiltros:{},
            isLoading: false,
            date: null,
            day: null,
            month: null,
            year: null,
            isLeapYear: false,
            monthLength: null,
            isISO8601: false,
            user: this.$store.state.iasctratkn ? JSON.parse(Buffer.from(this.$store.state.iasctratkn.split('.')[1], 'base64').toString()) : {},
        }
    },
    methods:{
        falseToken(err){
            console.log('Error iasc: '+ err)
            if(err == '{message: expired token}'){
                this.$store.dispatch('logout')
                if(this.$route.name == 'login') return
                this.$router.push({name: 'login'})
            }else{
                fetch(this.$api+'/err', {
                    method: "POST",
                    headers: { "Content-Type": "application/json" ,
                               'Authorization' : this.$store.state.iasctratkn },
                    body: JSON.stringify({  error: err })
                }).then((response)=>{
                    if(response.ok){
                        return response.json();
                    }   
                }).then(()=>{ })
            }
          },
        async Reset(reg){
            let res = await fetch(this.$api+'/rdx',{
                method: "POST",
                headers: { "Content-Type": "application/json" ,'Authorization' : this.$store.state.iasctratkn},
                body: JSON.stringify({
                            accion: "metodo", 
                            archivo: "Global", 
                            metodo: "Reset",
                            params: {reg:reg}
                    })
                }).then((response)=>{
                    if(response.ok){
                        return response.json()
                    }   
                }).then((data)=>{
                    return data
                })
            return res
        },
        async getSessionData(){
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.iasctratkn
                },
                body: JSON.stringify({ accion: "session" })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.$store.dispatch('saveSession',data)
            })
        },
        getFiltersData(){
            return fetch(this.$api+'/rdx', {
                method: "POST",
                headers: {
                    'Content-Type' : 'application/json',
                    'Authorization' : this.$store.state.iasctratkn
                },
                body: JSON.stringify({ accion: "filters"})
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                this.Gfiltros = data
            })
        },
        async getParams(){
            await fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", 
                                        archivo: "Global", 
                                        metodo: "INIParms",
                                        params: {} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
            this.parametros = data
            this.$store.dispatch('saveParams',this.parametros)
            })
        },
        formato(fecha,formato){
            
            if(fecha != '' && fecha != null){
                return moment(fecha).format(formato)
            }else{
                return ''
            }
        },
        isValidDate() {
            /*
              Valid formats:
      
              - M/D/YYYY
              - M/DD/YYYY
              - MM/D/YYYY
              - MM/DD/YYYY
              - YYYY-M-D
              - YYYY-M-DD
              - YYYY-MM-D
              - YYYY-MM-DD
            */
            if (!/^(\d{1,2}\/\d{1,2}\/\d{4,}|\d{4,}-\d{1,2}-\d{1,2})$/.test(this.date)) {
              return false;
            }
      
            this.isISO8601 = /^\d{4}-\d{1,2}-\d{1,2}$/.test(this.date);
      
            // Get the month, day, and year parts
            var parts = this.date.split(this.isISO8601 ? "-" : "/");
            this.month = parseInt(parts[this.isISO8601 ? 1 : 0], 10);
            this.day = parseInt(parts[this.isISO8601 ? 2 : 1], 10);
            this.year = parseInt(parts[this.isISO8601 ? 0 : 2], 10);
      
            // Should be a valid javascript month 0-11
            if (this.month === 0 || this.month > 12) {
              return false;
            }
      
            // Valid month lengths
            var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
            // Check for leap years
            if (this.year % 400 === 0 || (this.year % 100 !== 0 && this.year % 4 === 0)) {
              this.isLeapYear = true;
              monthLength[1]++;
            } else {
              this.isLeapYear = false;
            }
      
            return this.day > 0 && this.day <= monthLength[this.month - 1];
        },
        updateParams(){
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json" ,
                           'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: "metodo", 
                                        archivo: "Global", 
                                        metodo: "INIParms",
                                        params: {} })
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
              this.parametros = data
              this.$store.dispatch('saveParams',this.parametros)
            })
        }
        
    }  
}
<template>
    <section>
        <SideBar />
        <TopBar />
        <div class="content">
            <div class="reduced-fit">
                <div class="main-content-header">
                    <h1>Caja Chica</h1>
                </div>
                <LoadingContent v-if="isLoading" />
                <div class="row w-65">
                    <div class="col-lg-12 ">
                        <div class="card mb-30" >
                            <div class="card-content" >
                                <div class="card-body">
                                    <div class="card-header" >
                                        <h5 class="card-title">{{ titulo }}</h5>
                                    </div>
                                    <div class="row">
                                        <div class="col-10">
                                            <form>
                                                <div class="row ">
                                                    <div class="col-6">
                                                        <div class="row ">
                                                            <label class="col-3 text-nowrap">NumID</label>
                                                            <div class="col-5"> 
                                                                <input ref="numid" type="number" maxlength="7" class="form-control form-control-sm" readonly v-model="caja.numid" />													
                                                            </div>
                                                        </div>	
                                                    </div>	
                                                    <div class="col-6">
                                                        <div class="row ">
                                                            <label class="col-3 text-nowrap">Fecha</label>
                                                            <div class="col-5">
                                                                <input ref="fecha" type="date" class="form-control form-control-sm" readonly v-model="caja.fecha" />													
                                                            </div>
                                                        </div>	
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="row ">
                                                            <label class="col-3 text-nowrap">Saldo Anterior</label>
                                                            <div class="col-6">
                                                                <input ref="saldoant"  type="number" class="form-control form-control-sm" :disabled="modo=='Modificando'" v-model="caja.saldoant" />
                                                            </div>
                                                        </div>	
                                                        <div class="row">
                                                            <label class="col-3 text-nowrap text-right">Reposición</label>
                                                            <div class="col-6">
                                                                <input ref="reposicion" maxlength="7" type="number" :disabled="modo=='Modificando'" class="form-control form-control-sm" v-model="caja.reposicion" />
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <label class="col-3 text-nowrap text-right">Monto</label>
                                                            <div class="col-6">
                                                                <input ref="monto" type="number" class="form-control form-control-sm" :disabled="modo=='Modificando'" v-model="caja.monto" />
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <label class="col-3 text-nowrap text-right">Usuario</label>
                                                            <div class="col-6">
                                                                <input ref="usuario" maxlength="12" type="text" class="form-control form-control-sm" readonly v-model="caja.usuario" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-6">
                                                        <div class="row ">
                                                            <label class="col-3 text-nowrap">Generados</label>
                                                            <div class="col-6">
                                                                <input ref="generados" type="number" class="form-control form-control-sm" readonly v-model="generado" />
                                                            </div>
                                                        </div>	
                                                        <div class="row">
                                                            <label class="col-3 text-nowrap text-right">Entregados</label>
                                                            <div class="col-6">
                                                                <input ref="entregados"  type="number" class="form-control form-control-sm" readonly v-model="entregado" />
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <label class="col-3 text-nowrap text-right">Liquidados</label>
                                                            <div class="col-6">
                                                                <input ref="liquidados" type="number" class="form-control form-control-sm" readonly v-model="liquidado" />
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <label class="col-3 text-nowrap text-right">Saldo Final</label>
                                                            <div class="col-6">
                                                                <input ref="saldofin" type="number" class="form-control form-control-sm" readonly v-model="saldofin" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="table-responsive ps-2">
                                                            <b-table-simple sticky-header class="browse" hover small caption-top responsive >
                                                                <b-thead head-variant="light" class="table-header">
                                                                    <b-tr>
                                                                        <b-th class="text-nowrap">Suc</b-th>
                                                                        <b-th class="text-nowrap">Docto</b-th>
                                                                        <b-th class="text-nowrap">Folio</b-th>
                                                                        <b-th class="text-nowrap">Fecha</b-th>
                                                                        <b-th class="text-nowrap">Monto</b-th>
                                                                        <b-th class="text-nowrap">Operador</b-th>
                                                                        <b-th class="text-nowrap">Nombre</b-th>
                                                                        <b-th class="text-nowrap">Tractor</b-th>
                                                                        <b-th class="text-nowrap">Estatus</b-th>
                                                                    </b-tr>
                                                                </b-thead>
                                                                <b-tbody v-if="anticipos.length > 0">
                                                                    <b-tr v-for="(ant,indx) in anticipos" :key="indx" 
                                                                    @click="selectedAnticipo = ant;selectedAnticipo.indx = indx"  
                                                                    @dblclick="todisponibles()"
                                                                    :class="{'selected-row': (selectedAnticipo.folio == ant.folio),
                                                                             'son-bkg-lightblue':(ant.estatus == 'GENERADO'),
                                                                             'son-bkg-lightgreen':(ant.estatus == 'ENTREGADO'),
                                                                             'son-bkg-lightorange':(ant.estatus == 'LIQUIDADO')}">
                                                                        <b-td class="text-nowrap text-center">{{ ant.sucursal| leadingZeros(3)}}</b-td>
                                                                        <b-td class="text-nowrap text-center">{{ ant.docto}}</b-td>
                                                                        <b-td class="text-center text-nowrap" >{{ ant.folio | leadingZeros(7)}}</b-td>
                                                                        <b-td class="text-nowrap text-end">{{ formato( ant.fecha  ,"MMM-DD-YYYY")  }}</b-td>
                                                                        <b-td class="text-nowrap text-end">{{ parseFloat(ant.monto) | toCurrency  }}</b-td>
                                                                        <b-td class="text-nowrap text-center">{{ ant.operador| leadingZeros(6) }}</b-td>
                                                                        <b-td class="text-nowrap">{{ ant.nombre }}</b-td>
                                                                        <b-td class="text-nowrap text-center">{{ ant.tractor }}</b-td>
                                                                        <b-td class="text-nowrap">{{ ant.estatus }}</b-td>
                                                                    </b-tr>
                                                                </b-tbody>
                                                                <b-tbody v-else>
                                                                    <b-tr  >
                                                                        <b-td class="text-center" :variant="'secondary'" colspan="9"><p class="p-4">NO HAY ANTICIPOS</p></b-td>
                                                                    </b-tr>
                                                                </b-tbody>
                                                            </b-table-simple>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div class="row">
                                                    <div class="col-6">
                                                        <label class="text-info">Selecciona con doble click para incluir en Caja:</label>
                                                    </div>
                                                    <div class="col-4 d-flex">
                                                        <div class="form-check">
                                                            <input class="form-check-input" @change="cargaantLiq()" v-model="antliq" type="radio" value="A" id="check_anticipos">
                                                            <label class="form-check-label" for="check_anticipos">Anticipos</label>
                                                        </div>
                                                        <div class="form-check">
                                                            <input class="form-check-input" @change="cargaantLiq()"  v-model="antliq" type="radio" value="L" id="check_liq">
                                                            <label class="form-check-label" for="check_liq">Liquidaciones</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-2 text-center">
                                                        <button class="btn btn-outline-secondary btn-sm">Grupos</button>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="table-responsive ps-2">
                                                            <b-table-simple sticky-header class="browse" hover small caption-top responsive >
                                                                <b-thead head-variant="light" class="table-header">
                                                                    <b-tr>
                                                                        <b-th class="text-nowrap">Suc</b-th>
                                                                        <b-th class="text-nowrap">Docto</b-th>
                                                                        <b-th class="text-nowrap">Folio</b-th>
                                                                        <b-th class="text-nowrap">Fecha</b-th>
                                                                        <b-th class="text-nowrap">Monto</b-th>
                                                                        <b-th class="text-nowrap">Operador</b-th>
                                                                        <b-th class="text-nowrap">Nombre</b-th>
                                                                        <b-th class="text-nowrap">Tractor</b-th>
                                                                        <b-th class="text-nowrap">Estatus</b-th>
                                                                    </b-tr>
                                                                </b-thead>
                                                                <b-tbody v-if="disponibles.length > 0">
                                                                    <b-tr v-for="(ant,indx) in disponibles" :key="indx" 
                                                                    @click="selectedDisponible = ant;selectedDisponible.indx = indx"  
                                                                    @dblclick="toanticipos()"
                                                                    :class="{'selected-row': (selectedDisponible.folio == ant.folio),
                                                                             'son-bkg-lightblue':(ant.estatus == 'GENERADO'),
                                                                             'son-bkg-lightgreen':(ant.estatus == 'ENTREGADO'),
                                                                             'son-bkg-lightorange':(ant.estatus == 'LIQUIDADO')}">
                                                                        <b-td class="text-nowrap text-center">{{ ant.sucursal| leadingZeros(3) }}</b-td>
                                                                        <b-td class="text-nowrap text-center">{{ ant.docto }}</b-td>
                                                                        <b-td class="text-center text-nowrap" >{{ ant.folio | leadingZeros(7) }}</b-td>
                                                                        <b-td class="text-nowrap text-end">{{ formato( ant.fecha  ,"MMM-DD-YYYY") }}</b-td>
                                                                        <b-td class="text-nowrap text-end">{{ parseFloat(ant.monto) | toCurrency }}</b-td>
                                                                        <b-td class="text-nowrap text-center">{{ ant.operador| leadingZeros(6) }}</b-td>
                                                                        <b-td class="text-nowrap">{{ ant.nombre }}</b-td>
                                                                        <b-td class="text-nowrap text-center">{{ ant.tractor }}</b-td>
                                                                        <b-td class="text-nowrap">{{ ant.estatus }}</b-td>
                                                                    </b-tr>
                                                                </b-tbody>
                                                                <b-tbody v-else>
                                                                    <b-tr  >
                                                                        <b-td class="text-center" :variant="'secondary'" colspan="9"><p class="p-4">NO HAY ANTICIPOS</p></b-td>
                                                                    </b-tr>
                                                                </b-tbody>
                                                            </b-table-simple>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-2">
                                            <ul class="d-block p-1 list-unstyled controls-form">
                                                <li><button @click="setCajaChica()" :disabled="GR_Cierre_disabled" class="btn btn-sm btn-success btn-block">Aceptar</button></li>
                                                <li><router-link to="/cajachica" :disabled="GR_Cierre_disabled" class="btn btn-sm btn-danger btn-block">Salir</router-link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import handleForm from '@/mixins/handleForm';
import LoadingContent from '@/components/LoadingSpinner.vue'
import handleGlobals from '@/mixins/handleGlobals'

import SideBar from '@/components/SideBarNav.vue';
import TopBar from '@/components/TopBarNav.vue';
export default {
    components:{SideBar, TopBar,LoadingContent },
    mixins:[handleForm,handleGlobals],
    data(){
        return{
            id: this.$route.params.caja,
            modo: (this.$route.params.caja > 0 ? 'Modificando' : 'Creacion'), 
            caja: {},
            titulo: 'Agregando una Caja',
            selectedAnticipo:{},
            selectedDisponible:{},
            anticipos:[],
            disponibles:[],
            GR_Cierre_disabled:false,
            antliq: 'A'
        }
    },
    computed: {
        generado() {
            let res
            if(this.anticipos) {
                res =  this.anticipos.filter(e=>e.estatus == 'GENERADO').reduce((acc, item) => acc + item.monto, 0)
            }else{
                res = 0
            }
            return res
        },
        entregado() {
            let res
            if(this.anticipos) {
                res =  this.anticipos.filter(e=>e.estatus == 'ENTREGADO').reduce((acc, item) => acc + item.monto, 0)
            }else{
                res = 0
            }
            return res
        },
        liquidado() {
            let res
            if(this.anticipos) {
                res =  this.anticipos.filter(e=>e.estatus == 'LIQUIDADO').reduce((acc, item) => acc + item.monto, 0)
            }else{
                res = 0
            }
            return res
        },
        saldofin(){
            return this.caja.saldoant + this.caja.monto - (this.entregado + this.liquidado)
        }
    },
    mounted(){
        this.llenaForma();
    },
    methods:{
        async llenaForma(){
            await this.getCajaChica();
            this.titulo = this.$route.params.caja > 0 ? 'Cambiando una Caja' : 'Agregando una Caja'
            if(this.modo == 'Modificando'){
                this.carga()
            }
           
        },
        getCajaChica(){
            let req = []
            this.isLoading = true
             fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "inicializar", modo:this.modo, archivo: "faCajaChica", id: this.id })
            }).then((response)=>{
                if(response.ok){
                    return response.json()
                }   
            }).then((data)=>{
                this.caja = data['res']
                this.anticipos = data['anticipos']
                this.disponibles = data['disponibles']

                if(this.caja.usuariocierre == ""){
                    this.CargaNoAsignadas()
                }else{
                    this.GR_Cierre_disabled = true
                }
                req = data['req']
            }).finally(()=>{
                this.setRequired(req)
                this.isLoading = false
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        CargaNoAsignadas(){
             fetch(this.$api+'/rdx',{
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({ accion: "metodo", metodo:'CargaNoAsignadas', archivo: "faCajaChica", params: {fecha:this.caja.fecha} })
            }).then((response)=>{
                if(response.ok){
                    return response.json()
                }   
            }).then((data)=>{
                this.disponibles = data
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        setCajaChica(){
            this.isLoading = true
            this.caja.saldofin = this.saldofin
            fetch(this.$api+'/rdx', {
                method: "POST",
                headers: { "Content-Type": "application/json",
                            'Authorization' : this.$store.state.iasctratkn },
                body: JSON.stringify({  accion: 'validar', archivo: "faCajaChica", modo: this.modo, record: this.caja }) 
            }).then((response)=>{
                if(response.ok){
                    return response.json();
                }   
            }).then((data)=>{
                if(data['res'] == 'error'){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = data['msg']
                    this.notificationRef = 'numid'
                }else{
                    this.isLoading = false
                    this.$router.push('/cajachica')
                }
            }).catch((err)=>{
                this.falseToken(err)
            });
        },
        afterClose(){
            this.$refs[this.notificationRef].focus()
        },
        todisponibles(){
            this.disponibles.push(this.selectedAnticipo)
            this.anticipos = this.anticipos.filter(e=>e.folio != this.selectedAnticipo.folio)
        },
        toanticipos(){
            if(this.selectedAnticipo.monto > this.saldofin){
                    this.showNotification = true
                    this.notificationTitle =  'Error'
                    this.notificationText = 'No puedes incluir este anticipo a la caja porque no hay saldo suficiente'
                    return
            }
            this.anticipos.push(this.selectedDisponible)
            this.disponibles = this.disponibles.filter(e=>e.folio != this.selectedDisponible.folio)
        },
        cargaantLiq(){

        }
    }
}
</script>
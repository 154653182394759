<template>
  <nav class="navbar navbar-vertical navbar-expand-lg" >
    <div class="collapse navbar-collapse" id="navbarVerticalCollapse">
      <div class="navbar-vertical-content">
        <ul class="navbar-nav flex-column" id="navbarVerticalNav">
          <li class="nav-item">
            <div class="nav-item-wrapper">
              <a class="nav-link dropdown-indicator label-1 collapsed" href="#archivos" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="archivos">
                <div class="d-flex align-items-center">
                  <div class="dropdown-indicator-icon">
                    <span class="fas fa-caret-right"></span>
                  </div>
                  <span class="nav-link-text">Archivos</span>
                </div>
              </a>
              <div class="parent-wrapper label-1">
                <ul class="nav collapse parent " data-bs-parent="#navbarVerticalCollapse" id="archivos">
                  <li class="collapsed-nav-item-title d-none">Archivos</li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/operadores" role="button" >
                      <div class="d-flex align-items-center"><span class="nav-link-text">Maestro de Operadores</span></div>
                    </router-link>
                    <!-- more inner pages-->
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/vehiculos" >
                      <div class="d-flex align-items-center"><span class="nav-link-text">Maestro de Vehículos</span></div>
                    </router-link>
                  </li>
                  
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/clientes" >
                      <div class="d-flex align-items-center"><span class="nav-link-text">Maestro de Clientes</span></div>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/casetas" >
                      <div class="d-flex align-items-center"><span class="nav-link-text text-success">Maestro de Casetas</span></div>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/trdestinos" >
                      <div class="d-flex align-items-center"><span class="nav-link-text text-success">Maestro de Destinos</span></div>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/ciudades" >
                      <div class="d-flex align-items-center"><span class="nav-link-text text-success">Maestro de Ciudades</span></div>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/distancias" >
                      <div class="d-flex align-items-center"><span class="nav-link-text text-success">Tabla de Distancias</span></div>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/servicios" >
                      <div class="d-flex align-items-center"><span class="nav-link-text">Catálogo de Servicios</span></div>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/moneda" >
                      <div class="d-flex align-items-center"><span class="nav-link-text text-success">Monedas</span></div>
                    </router-link>
                  </li>
                  <div class="nav-item-wrapper">
                    <a class="nav-link dropdown-indicator collapsed label-1" href="#facturacion" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="facturacion">
                      <div class="d-flex align-items-center">
                        <div class="dropdown-indicator-icon"><svg class="svg-inline--fa fa-caret-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg="">
                          <path fill="currentColor" d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z"></path></svg>
                        </div>
                        <span class="nav-link-text">Facturación</span>
                      </div>
                    </a>
                    <div class="parent-wrapper label-1">
                      <ul class="nav parent collapse " data-bs-parent="#archivos" id="facturacion" style="">
                        <li class="collapsed-nav-item-title d-none">Facturación</li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/folios" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Folios Digitales</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/facturas" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Archivo de Facturas</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/ncredito" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Notas de Crédito</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/ncargo" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Notas de Cargo</span>
                            </div>
                          </router-link>
                        </li>
                        
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/relacion" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Relaciones de Entrega</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/tpotransacciones" >
                            <div class="d-flex align-items-center"><span class="nav-link-text text-success">Tipos de Transacciones</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/condiciones" >
                            <div class="d-flex align-items-center"><span class="nav-link-text text-success">Condiciones de Pago</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/historiatcambio" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Historia de T.Cambio</span>
                            </div>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nav-item-wrapper ">
                    <a class="nav-link dropdown-indicator collapsed label-1"  href="#remisiones" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="remisiones">
                      <div class="d-flex align-items-center">
                        <div class="dropdown-indicator-icon">
                          <svg class="svg-inline--fa fa-caret-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg="">
                          <path fill="currentColor" d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z"></path></svg>
                          <!-- <span class="fas fa-caret-right"></span> Font Awesome fontawesome.com -->
                        </div>
                        <span class="nav-link-icon d-none">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                        </span>
                        <span class="nav-link-text">Remisiones</span>
                      </div>
                    </a>
                    <div class="parent-wrapper label-1">
                      <ul class="nav parent collapse " data-bs-parent="#remmisiones" id="remisiones" style="">
                        <li class="collapsed-nav-item-title d-none">Remisiones</li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/embarques" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Ordenes de Embarque</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/embarquenva" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Ordenes de Embarque Nva</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/remisiones" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Archivo de Remisiones</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/ligarremfac" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Ligar Remisiones a Factura</span>
                            </div>
                          </router-link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nav-item-wrapper ">
                    <a class="nav-link dropdown-indicator collapsed label-1"  href="#liquidaciones" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="liquidaciones">
                      <div class="d-flex align-items-center">
                        <div class="dropdown-indicator-icon">
                          <svg class="svg-inline--fa fa-caret-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg="">
                          <path fill="currentColor" d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z"></path></svg>
                          <!-- <span class="fas fa-caret-right"></span> Font Awesome fontawesome.com -->
                        </div>
                        <span class="nav-link-icon d-none">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                        </span>
                        <span class="nav-link-text">Liquidaciones</span>
                      </div>
                    </a>
                    <div class="parent-wrapper label-1">
                      <ul class="nav parent collapse " data-bs-parent="#liquidaciones" id="liquidaciones" style="">
                        <li class="collapsed-nav-item-title d-none">Liquidaciones</li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/cajachica" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Caja Chica</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/capturaanticipos" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Captura de Anticipos</span>
                            </div>
                          </router-link>
                        </li>
                       
                      </ul>
                    </div>
                  </div>
                  <div class="nav-item-wrapper ">
                    <a class="nav-link dropdown-indicator collapsed label-1"  href="#controlesad" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="controlesad">
                      <div class="d-flex align-items-center">
                        <div class="dropdown-indicator-icon">
                          <svg class="svg-inline--fa fa-caret-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg="">
                          <path fill="currentColor" d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z"></path></svg>
                          <!-- <span class="fas fa-caret-right"></span> Font Awesome fontawesome.com -->
                        </div>
                        <span class="nav-link-icon d-none">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                        </span>
                        <span class="nav-link-text">Controles Adicionales</span>
                      </div>
                    </a>
                    <div class="parent-wrapper label-1">
                      <ul class="nav parent collapse " data-bs-parent="#controlesad" id="controlesad" style="">
                        <li class="collapsed-nav-item-title d-none">Controles Adicionales</li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/fianzas" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Fianzas e Intercambios</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/lineas" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Maestro en Líneas</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/rpt-fianzas" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Reporte de Fianzas</span>
                            </div>
                          </router-link>
                        </li>
                       
                      </ul>
                    </div>
                  </div>
                  
                  <div class="nav-item-wrapper">
                    <a class="nav-link dropdown-indicator collapsed label-1"  href="#archivos-de-soporte" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="multi-level">
                      <div class="d-flex align-items-center">
                        <div class="dropdown-indicator-icon"><svg class="svg-inline--fa fa-caret-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg="">
                          <path fill="currentColor" d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z"></path></svg>
                          <!-- <span class="fas fa-caret-right"></span> Font Awesome fontawesome.com -->
                        </div>
                        <!-- <span class="nav-link-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-layers"><polygon points="12 2 2 7 12 12 22 7 12 2"></polygon><polyline points="2 17 12 22 22 17"></polyline><polyline points="2 12 12 17 22 12"></polyline></svg>
                        </span> -->
                        <span class="nav-link-text">Archivos de Soporte</span>
                      </div>
                    </a>
                    <div class="parent-wrapper label-1">
                      <ul class="nav parent collapse " data-bs-parent="#archivos" id="archivos-de-soporte">
                        <li class="collapsed-nav-item-title d-none">Archivos de Soporte</li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/tpounidad" >
                            <div class="d-flex align-items-center"><span class="nav-link-text text-success">Tipos de Unidad</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/tpocombustible" >
                            <div class="d-flex align-items-center"><span class="nav-link-text text-success">Tipos de Combustible</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/tpocontenedores" >
                            <div class="d-flex align-items-center"><span class="nav-link-text text-success">Tipos de Contenedores</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/propietarios" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Popietarios de Vehiculos</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item" v-if="$ext == 'cst' || $ext=='tum'">
                          <router-link class="nav-link label-1"  to="/gpovehiculos" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Agrupaciones de Vehículos</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/unembalaje" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Unidades de Embalaje</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/contenidos" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Contenidos</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/credenciales" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Maestro de Credenciales</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="nav-link label-1"  to="/polizas" >
                            <div class="d-flex align-items-center"><span class="nav-link-text">Polizas de Seguro</span>
                            </div>
                          </router-link>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link dropdown-indicator collapsed" href="#catalogo-sat" data-bs-toggle="collapse" aria-expanded="false" aria-controls="catalogo-sat">
                            <div class="d-flex align-items-center">
                              <div class="dropdown-indicator-icon">
                                <svg class="svg-inline--fa fa-caret-right" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" data-fa-i2svg="">
                                  <path fill="currentColor" d="M118.6 105.4l128 127.1C252.9 239.6 256 247.8 256 255.1s-3.125 16.38-9.375 22.63l-128 127.1c-9.156 9.156-22.91 11.9-34.88 6.943S64 396.9 64 383.1V128c0-12.94 7.781-24.62 19.75-29.58S109.5 96.23 118.6 105.4z"></path>
                                </svg>
                                <!-- <span class="fas fa-caret-right"></span> Font Awesome fontawesome.com -->
                              </div>
                              <span class="nav-link-text">Catálogo SAT</span>
                            </div>
                          </a>
                          <!-- more inner pages-->
                          <div class="parent-wrapper">
                            <ul class="nav collapse parent" data-bs-parent="#catalogo-sat" id="catalogo-sat">
                              <li class="nav-item">
                                <router-link class="nav-link" to="/sat-usocfdi" >
                                  <div class="d-flex align-items-center"><span class="nav-link-text">Uso CFDI</span>
                                  </div>
                                </router-link>
                                <!-- more inner pages-->
                              </li>
                              <li class="nav-item">
                                <router-link class="nav-link" to="/sat-rfiscal"  >
                                  <div class="d-flex align-items-center"><span class="nav-link-text">Régimen Fiscal</span>
                                  </div>
                                </router-link>
                                <!-- more inner pages-->
                              </li>
                              <li class="nav-item">
                                <router-link class="nav-link" to="/sat-estados"  >
                                  <div class="d-flex align-items-center"><span class="nav-link-text">Estados</span>
                                  </div>
                                </router-link>
                                <!-- more inner pages-->
                              </li>
                              <li class="nav-item">
                                <router-link class="nav-link " to="/sat-cpostal" >
                                  <div class="d-flex align-items-center"><span class="nav-link-text">Códigos Postales</span>
                                  </div>
                                </router-link>
                                <!-- more inner pages-->
                              </li>
                             
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                 
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/bancos" >
                      <div class="d-flex align-items-center"><span class="nav-link-text ">Maestro de Bancos</span></div>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/fichasdeposito" >
                      <div class="d-flex align-items-center"><span class="nav-link-text ">Fichas de Depósito</span></div>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/comprobantespago" >
                      <div class="d-flex align-items-center"><span class="nav-link-text ">Comprobantes de Pago</span></div>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/anticipos" >
                      <div class="d-flex align-items-center"><span class="nav-link-text ">Anticipos</span></div>
                    </router-link>
                  </li>
                  
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/sucursales" >
                      <div class="d-flex align-items-center"><span class="nav-link-text text-success">Maestro de Sucursales</span></div>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/tipodocumentos" >
                      <div class="d-flex align-items-center"><span class="nav-link-text ">Tipo de Documentos</span></div>
                    </router-link>
                  </li>
                 
                  <li class="nav-item">
                    <a href="#" @click="getPeriodo()" class="nav-link label-1"  >
                      <div class="d-flex align-items-center"><span class="nav-link-text">Períodos</span></div>
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
            <!-- parent pages-->
          </li>
          <li class="nav-item">
            <div class="nav-item-wrapper">
              <a class="nav-link dropdown-indicator collapsed label-1" href="#trafico" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="trafico">
                <div class="d-flex align-items-center">
                  <div class="dropdown-indicator-icon">
                    <span class="fas fa-caret-right"></span>
                  </div>
                  <span class="nav-link-text">Tráfico</span>
                </div>
              </a>
              <div class="parent-wrapper label-1">
                <ul class="nav collapse parent " data-bs-parent="#archivos" id="trafico">
                  <li class="collapsed-nav-item-title d-none">Tráfico</li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/traficos" >
                      <div class="d-flex align-items-center"><span class="nav-link-text">Control de Tráfico</span>
                      </div>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/trdestinos" >
                      <div class="d-flex align-items-center"><span class="nav-link-text">Destinos</span>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <div class="nav-item-wrapper">
              <a class="nav-link dropdown-indicator collapsed label-1" href="#gps" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="gps">
                <div class="d-flex align-items-center">
                  <div class="dropdown-indicator-icon">
                    <span class="fas fa-caret-right"></span>
                  </div>
                  <span class="nav-link-text">GPS</span>
                </div>
              </a>
              <div class="parent-wrapper label-1">
                <ul class="nav collapse parent " data-bs-parent="#archivos-de-soporte" id="gps">
                  <li class="collapsed-nav-item-title d-none">GPS</li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/gpsgeozonas" >
                      <div class="d-flex align-items-center"><span class="nav-link-text">GeoZonas</span>
                      </div>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/gpsdevices" >
                      <div class="d-flex align-items-center"><span class="nav-link-text">Devices</span>
                      </div>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link class="nav-link label-1"  to="/gpseventos" >
                      <div class="d-flex align-items-center"><span class="nav-link-text">Eventos</span>
                      </div>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="navbar-vertical-footer" v-show="false">
      <button class="btn navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center">
        <span class="uil uil-left-arrow-to-left fs-0"></span>
			<span class="uil uil-arrow-from-right fs-0"></span>
        <span class="navbar-vertical-footer-text ms-2">Colapsar</span>
      </button>
    </div>

    <Periodos v-if="openPeriodo" @close="openPeriodo = false" class="popup-static" :size="25" @goAhead="savePeriodo()"> 
      <template v-slot:title>Periodos</template>
      <template v-slot:body>
          <div class="row">
            <div class="col-6">
              <div class="row">
                <label class="col-3 text-nowrap text-right">Año</label>
                <div class="col-9">
                  <select class="form-control form-control-sm" v-model="periodo.ano">
                    <option value=""></option>
                    <option v-for="(ano, key) in anios" :key="key" :value="ano">{{ano}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-6">
              <input type="hidden" class="form-control form-control-sm"  v-model="periodo.anoanterior" />													
            </div>
            
          </div>
          <div class="row">
            <div class="col-3">
              <div class="form-check">
                <input class="form-check-input"  v-model="periodo.periodos.per_01 "  type="checkbox" id="enero">
                <label class="form-check-label" for="enero">Enero</label>
              </div>
              <div class="form-check">
                <input class="form-check-input"  v-model="periodo.periodos.per_02 "  type="checkbox" id="febrero">
                <label class="form-check-label" for="febrero">Febrero</label>
              </div>
              <div class="form-check">
                <input class="form-check-input"  v-model="periodo.periodos.per_03 "  type="checkbox" id="marzo">
                <label class="form-check-label" for="marzo">Marzo</label>
              </div>
            </div>
            <div class="col-3">
              <div class="form-check">
                <input class="form-check-input"  v-model="periodo.periodos.per_04 "  type="checkbox" id="abril">
                <label class="form-check-label" for="abril">Abril</label>
              </div>
              <div class="form-check">
                <input class="form-check-input"  v-model="periodo.periodos.per_05 "  type="checkbox" id="mayo">
                <label class="form-check-label" for="mayo">Mayo</label>
              </div>
              <div class="form-check">
                <input class="form-check-input"  v-model="periodo.periodos.per_06 "  type="checkbox" id="junio">
                <label class="form-check-label" for="junio">Junio</label>
              </div>
            </div>
            <div class="col-3">
              <div class="form-check">
                <input class="form-check-input"  v-model="periodo.periodos.per_07 "  type="checkbox" id="julio">
                <label class="form-check-label" for="julio">Julio</label>
              </div>
              <div class="form-check">
                <input class="form-check-input"  v-model="periodo.periodos.per_08 "  type="checkbox" id="agosto">
                <label class="form-check-label" for="agosto">Agosto</label>
              </div>
              <div class="form-check">
                <input class="form-check-input"  v-model="periodo.periodos.per_09 "  type="checkbox" id="septiembre">
                <label class="form-check-label" for="septiembre">Septiembre</label>
              </div>
            </div>
            <div class="col-3">
              <div class="form-check">
                <input class="form-check-input"  v-model="periodo.periodos.per_10 "  type="checkbox" id="octubre">
                <label class="form-check-label" for="octubre">Octubre</label>
              </div>
              <div class="form-check">
                <input class="form-check-input"  v-model="periodo.periodos.per_11 "  type="checkbox" id="noviembre">
                <label class="form-check-label" for="noviembre">Noviembre</label>
              </div>
              <div class="form-check">
                <input class="form-check-input"  v-model="periodo.periodos.per_12 "  type="checkbox" id="diciembre">
                <label class="form-check-label" for="diciembre">Diciembre</label>
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-12">
              <p class="text-info text-center">checar la casilla del mes que quieres abrir</p>
            </div>
          </div>
      </template>
      
    </Periodos>
  </nav>
</template>

<script>
import Periodos from '@/components/PopupWindow.vue';

export default {
    name: 'SideBarNav',
    components:{Periodos},
    data(){
      return{
        openPeriodo: false,
        periodo: {},
        anios:[]
      }
    },
    methods:{
      getPeriodo(){
        this.isLoading = true
          fetch(this.$api+'/rdx', {
            method: "POST",
            headers: { "Content-Type": "application/json",
                        'Authorization' : this.$store.state.iasctratkn },
            body: JSON.stringify({ accion: "metodo", metodo:'GetPeriodos', archivo: "Global", params:{} })
        }).then((response)=>{
            if(response.ok){
                return response.json()
            }   
        }).then((data)=>{
            this.anios = data['anios']
            this.periodo.periodos = data['periodos']
            this.periodo.anoanterior = data['anoanterior']
            this.periodo.ano = data['dTodayYear']
            this.isLoading = false
            this.openPeriodo = true
        }).catch((err)=>{
            this.falseToken(err)
        });
      },
      savePeriodo(){
        this.isLoading = true
          fetch(this.$api+'/rdx', {
            method: "POST",
            headers: { "Content-Type": "application/json",
                        'Authorization' : this.$store.state.iasctratkn },
            body: JSON.stringify({ accion: "metodo", metodo:'PutPeriodos', archivo: "Global", params:{periodos: this.periodo} })
        }).then((response)=>{
            if(response.ok){
                return response.json()
            }   
        }).then((data)=>{
            if(data) this.openPeriodo = false
            this.isLoading = false
        }).catch((err)=>{
            this.falseToken(err)
        });
      }
    }
  }
</script>